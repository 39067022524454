import React from 'react'

const Footer = () => {
  return (
    <div>
        {/* <!-- Footer  --> */}
    <footer id="footer_area">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                    <div className="footer_heading_area">
                        <h5>Need any help?</h5>
                    </div>
                    <div className="footer_first_area">
                        <div className="footer_inquery_area">
                            <h5>Call 24/7 for any help</h5>
                            <h3> <a href="tel:+00-123-456-789">020 3287 1342</a></h3>
                        </div>
                        <div className="footer_inquery_area">
                            <h5>Mail to our support team</h5>
                            <h3> <a href="info@flightsway.co.uk">info@flightsway.co.uk</a></h3>
                        </div>
                        <div className="footer_inquery_area">
                            <h5>Follow us on</h5>
                            <ul className="soical_icon_footer">
                                <li><a href="https://www.facebook.com/FlightsWayltd"><i className="fab fa-facebook"></i></a></li>
                                <li><a href="https://www.instagram.com/flightswayuk"><i className="fab fa-instagram"></i></a></li>
                                <li><a href="#!"><i className="fab fa-whatsapp"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 offset-lg-1 col-md-6 col-sm-6 col-12">
                    <div className="footer_heading_area">
                        <h5>Company</h5>
                    </div>
                    <div className="footer_link_area">
                        <ul>
                            <li><a href="">About Us</a></li>
                            <li><a href="">Testimonials</a></li>
                            <li><a href="">Rewards</a></li>
                            <li><a href="">Work with Us</a></li>
                            <li><a href="">Meet the Team </a></li>
                            <li><a href="">Blog</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                    <div className="footer_heading_area">
                        <h5>Support</h5>
                    </div>
                    <div className="footer_link_area">
                        <ul>
                            <li><a href="">Account</a></li>
                            <li><a href="">Faq</a></li>
                            <li><a href="">Legal</a></li>
                            <li><a href="">Contact</a></li>
                            
                            <li><a href="/privacy">Privacy Policy</a></li>
                            <li><a href="/terms"> Terms Of Use</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                    <div className="footer_heading_area">
                        <h5>Other Services</h5>
                    </div>
                    <div className="footer_link_area">
                        <ul>
                            <li><a href="">Community program</a></li>
                            <li><a href="">Investor Relations</a></li>
                            <li><a href="">Rewards Program</a></li>
                            <li><a href="">PointsPLUS</a></li>
                            <li><a href="">Partners</a></li>
                            <li><a href="">List My Hotel</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                    <div className="footer_heading_area">
                        <h5>Top cities</h5>
                    </div>
                    <div className="footer_link_area">
                        <ul>
                            <li><a href="">Australia</a></li>
                            <li><a href="">India</a></li>
                            <li><a href="">Ghana</a></li>
                            <li><a href="">Nigeria</a></li>
                            <li><a href="">Pakistan </a></li>
                            <li><a href="">philippines</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <div className="copyright_area">
        <div className="container">
            <div className="row align-items-center">
                <div className="co-lg-6 col-md-6 col-sm-12 col-12">
                    <div className="copyright_left">
                        <p>Copyright © 2024 <a href='/'>FlightsWay</a> All Rights Reserved</p>
                    </div>
                </div>
                <div className="co-lg-6 col-md-6 col-sm-12 col-12">
                    <div className="copyright_right">
                        <img src="assets/img/common/cards.jpeg" alt="img"/>
                    </div>
                </div>
                
                
            </div>
        </div>
    </div>
    <div className="go-top">
        <i className="fas fa-chevron-up"></i>
        <i className="fas fa-chevron-up"></i>
    </div> 
    </div>
  )
}

export default Footer