import React from 'react'
import Form from './Form'
import { useState } from 'react'
import "../App.css"
import Header from './Header'
import Footer from './Footer'
import { useNavigate } from 'react-router-dom';





const Home = () => {

    const [iframeurl, setIframeurl] = useState('');
    const [loading, setLoading]= useState(false);
    const [error, setError] = useState(false);
    const [resultSuccess, setResultSuccess]= useState(false);
    const navigate = useNavigate();


    // const mycallback = (data)=>{
    //     setIframeurl(data.result_url)
    // }
    const mycallback = (data) => {
        setIframeurl(data.result_url); // You might want to check your data attribute names
        console.log(data.result_url)
        navigate('/results', { state: { iframeurl: data.result_url } }); // Navigate with state
    };
    const errorCallback = (state)=>{
        setError(state)
    }
    const loadingCallback = (state)=>{
        setLoading(state)
    }
    const resultCallback = (state)=>{
        setResultSuccess(state)
    }
  return (
    <div>

        <Header/>

         {/* <!-- search --> */}
    <div className="search-overlay">
        <div className="d-table">
            <div className="d-table-cell">
                <div className="search-overlay-layer"></div>
                <div className="search-overlay-layer"></div>
                <div className="search-overlay-layer"></div>
                <div className="search-overlay-close">
                    <span className="search-overlay-close-line"></span>
                    <span className="search-overlay-close-line"></span>
                </div>
                <div className="search-overlay-form">
                    <form>
                        <input type="text" className="input-search" placeholder="Search here..."/>
                        <button type="button"><i className="fas fa-search"></i></button>
                    </form>
                </div>
            </div>
        </div>
    </div>

    {/* <!-- Banner Area --> */}
    <section id="home_one_banner">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-12">
                    <div className="banner_one_text">
                         {/* <h1>Your Trusted Travel Agency In UK To See The World</h1> */}
                        {/* <h3>Find awesome flights, hotel, tour, car and packages</h3> */}
                    </div>
                </div>
            </div>
        </div>
    </section>

    <Form mycallback = {mycallback} errorCallback={errorCallback} loadingCallback={loadingCallback} resultCallback = {resultCallback} />





<section id="go_beyond_area" className="section_padding_top">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                    <div className="heading_left_area">
                        <h2>OUR SERVICES<span></span></h2>
                        <h5>We Offer Best Services For You</h5>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                    <div className="imagination_boxed">
                        <a href="">
                            <img src="assets/img/imagination/imagination1.jpg" alt="img"/>
                        </a>
                        <h3><a href="top-destinations.html">7% Discount for all <span>Airlines</span></a></h3>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                    <div className="imagination_boxed">
                        <a href="">
                            <img src="assets/img/imagination/imagination2.jpg" alt="img"/>
                        </a>
                        <h3><a href="#!">Travel around<span>the world</span></a></h3>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                    <div className="imagination_boxed">
                        <a href="">
                            <img src="assets/img/imagination/imagination3.jpg" alt="img"/>
                        </a>
                        <h3><a href="">Luxury resorts<span>top deals</span></a></h3>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* <!-- Top destinations --> */}
    <section id="top_destinations" className="section_padding_top">
        <div className="container">
            {/* <!-- Section Heading --> */}
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="section_heading_center">
                        <h2>Top destinations</h2>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="destinations_content_box img_animation">
                        <img src="assets/img/destination/big-img.jpeg" alt="img"/>
                        <div className="destinations_content_inner">
                            <h2>Up to</h2>
                            <div className="destinations_big_offer">
                                <h1>50</h1>
                                <h6><span>%</span> <span>Off</span></h6>
                            </div>
                            <h2>Holiday packages</h2>
                            <a href="" className="btn btn_theme btn_md">Book now</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="destinations_content_box img_animation">
                                <a href="">
                                    <img src="assets/img/destination/destination1.jpeg" alt="img"/>
                                </a>
                                <div className="destinations_content_inner">
                                    <h3><a href="">New york</a></h3>
                                </div>
                            </div>
                            <div className="destinations_content_box img_animation">
                                <a href="">
                                    <img src="assets/img/destination/destination2.jpeg" alt="img"/>
                                </a>
                                <div className="destinations_content_inner">
                                    <h3><a href="">Delhi</a></h3>
                                </div>
                            </div>
                            <div className="destinations_content_box img_animation">
                                <a href="">
                                    <img src="assets/img/destination/destination3.jpeg" alt="img"/>
                                </a>
                                <div className="destinations_content_inner">
                                    <h3><a href="">Turkey</a></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="destinations_content_box img_animation">
                                <a href="">
                                    <img src="assets/img/destination/destination4.jpeg" alt="img"/>
                                </a>
                                <div className="destinations_content_inner">
                                    <h3><a href="">Thailand</a></h3>
                                </div>
                            </div>
                            <div className="destinations_content_box img_animation">
                                <a href="">
                                    <img src="assets/img/destination/destination5.jpeg" alt="img"/>
                                </a>
                                <div className="destinations_content_inner">
                                    <h3><a href="">Dubia</a></h3>
                                </div>
                            </div>
                            <div className="destinations_content_box img_animation">
                                <a href="">
                                    <img src="assets/img/destination/destination6.jpeg" alt="img"/>
                                </a>
                                <div className="destinations_content_inner">
                                    <h3><a href="">Maldives</a></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="destinations_content_box img_animation">
                                <a href="">
                                    <img src="assets/img/destination/destination7.jpeg" alt="img"/>
                                </a>
                                <div className="destinations_content_inner">
                                    <h3><a href="">Caribbean</a></h3>
                                </div>
                            </div>
                            <div className="destinations_content_box img_animation">
                                <a href="">
                                    <img src="assets/img/destination/destination8.jpeg" alt="img"/>
                                </a>
                                <div className="destinations_content_inner">
                                    <h3><a href="">Mauritius</a></h3>
                                </div>
                            </div>
                            <div className="destinations_content_box">
                                <a href="" className="btn btn_theme btn_md w-100">View all</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    {/* <!-- Cta Area --> */}
    <section id="cta_area">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="cta_left">
                        <div className="cta_icon">
                            <img src="assets/img/common/email.png" alt="icon"/>
                        </div>
                        <div className="cta_content">
                            <h4>Get the latest news and offers</h4>
                            <h2>Subscribe to our newsletter</h2>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="cat_form">
                        <form id="cta_form_wrappper">
                            <div className="input-group"><input type="text" class="form-control"
                                    placeholder="Enter your mail address"/><button class="btn btn_theme btn_md"
                                    type="button">Subscribe</button></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <Footer/>

    <div id="mobile-sticky-footer" className='container-fluid d-lg-none'>
        <div className="row">
            <div className="col-12 p-0 sticky-nav-index" >
                <div className='mobile-sticky-nav text-center'>
                    <div className="row backk" >
                        <div className="col">
                            <a className='p-2 text-white' target='blank' href="https://api.whatsapp.com/send?phone=447762497412&text=Hi%20Flights%20Way%20UK,%20I%27m%20interested%20in%20flights%20deal%20for%20">
                                <i className='fab fa-whatsapp fa-2x'></i>
                                <span>Whatsapp</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    
    {/* <div className='whatsappBtn'>
    <button ><a href='https://wa.me/message/https://wa.me/00447762497412?text=Hello%20Flightways%20Team%2C%20I%20need%20help%20booking%20a%20flightVJDZMED22Z4SJ1'>
        <i className="fab fa-whatsapp fa-2x"></i>
        <span>Whatsapp</span></a></button>
    </div> */}



    {/* <button className='whatsappBtn' ><a href='https://wa.me/message/https://wa.me/00447762497412?text=Hello%20Flightways%20Team%2C%20I%20need%20help%20booking%20a%20flightVJDZMED22Z4SJ1'><i className="fab fa-whatsapp fa-2x"></i>Whatsapp</a></button> */}

    </div>
  )
}

export default Home
