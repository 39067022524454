import React, {useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "./components/Home";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfUse from "./components/TermsOfUse";
import ContactUS from "./components/ContactUS";
import ResultsPage from "./components/ResultsPage";
import FlightList from './components/FlightList';
import Login from './components/Login';

// const AuthContext = createContext();
// export const useAuth = () => useContext(AuthContext);


function App() {


    useEffect(() => {
  
        window.scrollTo(0, 0);
    }, []);



    return (
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/results" element={<ResultsPage />} />
                    <Route path="/privacy" element={<PrivacyPolicy />} />
                    <Route path="/terms" element={<TermsOfUse />} />
                    <Route path="/contact" element={<ContactUS />} />
                    <Route path="/users" element={<FlightList /> } />
                </Routes>
            </Router>
    );
}

export default App;