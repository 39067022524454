import React, { useState, useEffect } from 'react';
import {Data} from "./Data.js"
import { Modal, Button } from 'react-bootstrap'; 

const Form = (props) => {
  const base_url = "https://flightsway-service-ydsxc44xva-uc.a.run.app"
  // const base_url = "http://192.168.18.14:8000"
  // const base_url = "http://127.0.0.1:8000"
  const today = new Date().toISOString().substr(0, 10);

  
  

  const [fromValue, setFromValue] = useState('');
  const [toValue, setToValue] = useState('');
  
  const [dateValue, setDateValue] = useState(today);
  const [airports, setAirports] = useState([]);
  const [fromSuggestions, setFromSuggestions] = useState([]);
  const [toSuggestions, setToSuggestions] = useState([]);
  const [showFromSuggestions, setShowFromSuggestions] = useState(false);
  const [showToSuggestions, setShowToSuggestions] = useState(false);
  const [fromError, setFromError] = useState(false); // State for From field error
  const [toError, setToError] = useState(false); // State for To field error

  const [passenger, setPassenger]= useState(1)
  const [adult, setAdult] = useState(1)
  const [youth, setYouth] = useState(0)
  const [children, setChildren] = useState(0)
  const [infant, setInfant] = useState(0)
  const [cabinClass, setCabinClass] = useState('Economy')

  const [isReturn, setIsReturn] = useState(false)
  const [returnDate, setReturnDate] = useState(today);
  const [phoneNo, SetPhoneNo] = useState();
  const [phError, PhError] = useState(false); // State for To field error
  const [showModal, setShowModal] = useState(false);


const handleDateSelect=(e)=>{
      
      setDateValue(e.target.value)
}
  const calculatePassengers = ()=>{
      setPassenger(adult+youth+children+infant)
  }
  function formatDate(dateString) {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  }

  const handleAdultCount = (e,number) => {
    e.stopPropagation();
    setAdult((prevAdult) => prevAdult + number);
    calculatePassengers()
  };
  const handleYouthCount = (e,number) => {
    e.stopPropagation();
    setYouth((prevYouth) => prevYouth + number);
    calculatePassengers()
  };
  const handlechildrenCount =(e,number)=>{
    e.stopPropagation();
    setChildren((prevchild) => prevchild + number);
    calculatePassengers()

  }
  const handleinfantCount =(e,number)=>{
    e.stopPropagation();
    setInfant((previnfiant) => previnfiant + number);
    calculatePassengers()

  }
  
 

  const sendDataToParent = (data) => {
    // Call the callback function provided by the parent component
    props.mycallback(data);
  };

  useEffect(() => {
    calculatePassengers(); // Call calculatePassengers whenever any of the counts change
  }, [adult, children, infant]);
  

 
  // Function to handle input change for 'from' and 'to' fields
  const handleInputChange = (e, type) => {
    e.preventDefault()
    const value = e.target.value;

    if (type === 'from') {
    const location = Data.locations;
    // setFromSuggestions(location)
    setAirports(location);
    setFromValue(value);
    filterSuggestions(value, setFromSuggestions);
    }
   
    else if (type === 'to') {
      // Fetch airports based on the input value of 'to' field
    
          const location = Data.locations;
          // setToSuggestions(location)
          setAirports(location);
          setToValue(value);
          filterSuggestions(value, setToSuggestions);
    }
  };

  // // Function to filter suggestions based on input value
  // const filterSuggestions = (value, setSuggestions) => {
  //   if (!value.trim() || !airports || airports.length === 0) {
  //     setSuggestions([]);
  //     return;
  //   }

  //   const filteredSuggestions = airports.filter(airport =>
  //     airport.toLowerCase().includes(value.toLowerCase())
  //   );
  //   setSuggestions(filteredSuggestions);
  // };
  const filterSuggestions = (value, setSuggestions) => {
    if (!value.trim() || !airports || airports.length === 0) {
      setSuggestions([]);
      return;
    }
  
    // Filter suggestions based on the input value
    const filteredSuggestions = airports.filter(airport => {
      // Split the airport string into code, name, and country
      try {
        const [ country, nameWithCode] = airport.split(', ');
        const [name, code] = nameWithCode.split(' [');
        const trimmedCode = code.replace(/\]$/, '');
        if (trimmedCode.toLowerCase().includes(value.toLowerCase())) return true;
        // Check if the input value matches the airport name
        if (name.toLowerCase().includes(value.toLowerCase())) return true;
        // Check if the input value matches the airport's country
        if (country.toLowerCase().includes(value.toLowerCase())) return true;
      } catch (error) {
        return false;
      }

      // Return false if no match is found
      return false;
    });
  
    filteredSuggestions.sort((a, b) => {
      // Split suggestions into name, code, and country
      const [, nameA, codeA, countryA] = a.match(/^(.*?), (.*?) \[(.*?)\], (.*)$/);
      const [, nameB, codeB, countryB] = b.match(/^(.*?), (.*?) \[(.*?)\], (.*)$/);
  
      // Check if either code matches the input value
      if (codeA.toLowerCase() === value.toLowerCase()) return -1;
      if (codeB.toLowerCase() === value.toLowerCase()) return 1;
  
      // Check if either name matches the input value
      if (nameA.toLowerCase() === value.toLowerCase()) return -1;
      if (nameB.toLowerCase() === value.toLowerCase()) return 1;
  
      // Check if either country matches the input value
      if (countryA.toLowerCase() === value.toLowerCase()) return -1;
      if (countryB.toLowerCase() === value.toLowerCase()) return 1;
  
      // Check if either name starts with the input value
      if (nameA.toLowerCase().startsWith(value.toLowerCase())) return -1;
      if (nameB.toLowerCase().startsWith(value.toLowerCase())) return 1;
  
      // Fallback: Sort alphabetically
      return nameA.localeCompare(nameB);
  });
    setSuggestions(filteredSuggestions);
  };
  
  // Function to handle suggestion selection
  const selectSuggestion = (suggestion, type) => {
    if (type === 'from') {
      setFromValue(suggestion);
      setShowFromSuggestions(false);
    } else if (type === 'to') {
      setToValue(suggestion);
      setShowToSuggestions(false);
    }
  };

const fromSuggestionHandler=()=>{
  setShowFromSuggestions(true)
  setShowToSuggestions(false)
}
const toSuggestionHandler=()=>{
  setShowFromSuggestions(false)
  setShowToSuggestions(true)
}
  // Function to handle form submission
  const handleSearch = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Check if "From" and "To" fields are empty
    if (!fromValue.trim()) {
      setFromError(true); // Set From error state to true
      return; // Return early to prevent API call
    }

    if (!toValue.trim()) {
      setToError(true); // Set To error state to true
      return; // Return early to prevent API call
    }

    

    // If both fields are filled, proceed with API call

    const requestData = {
      Origin: fromValue,
      Destination: toValue,
      FromDate: formatDate(dateValue), // Add date to the request data
      Adults: adult, 
      Childs:children,
      Infants:infant,
      Youths:youth,
      PhoneNumber:phoneNo,
      // Add passenger count to the request data
      Class: cabinClass // Add cabin class to the request data
    };
    console.log(requestData)
    props.loadingCallback(true);
    console.log(requestData)
    fetch(`${base_url}/api/search-flight-youth/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    })
      .then(response => {
        if (!response.ok) {
          // props.loadingCallback(false);
          // props.errorCallback(true);
          setShowModal(true);

          throw new Error('Failed to search for flights');
        }
        return response.json();
      })
      .then(data => {
        props.loadingCallback(false);
        props.errorCallback(false);
        props.resultCallback(true)
        sendDataToParent(data)
        props.mycallback(data)
        console.log('Search results:', data);
      })
      .catch(error => {
        
      });
  };


  const handleRoundTripSearch = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Check if "From" and "To" fields are empty
    if (!fromValue.trim()) {
      setFromError(true); // Set From error state to true
      return; // Return early to prevent API call
    }

    if (!toValue.trim()) {
      setToError(true); // Set To error state to true
      return; // Return early to prevent API call
    }

    // If both fields are filled, proceed with API call
    const requestData = {
      Origin: fromValue,
      Destination: toValue,
      FromDate: formatDate(dateValue),
      ToDate: formatDate(returnDate), // Add date to the request data
      Adults: adult, 
      Childs:children,
      Infants:infant,
      // Add passenger count to the request data
      Class: cabinClass // Add cabin class to the request data
    };
   
    props.loadingCallback(true);
    console.log(requestData)
    fetch(`${base_url}/api/search-flight-roundtrip/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    })
      .then(response => {
        if (!response.ok) {
          props.loadingCallback(false);
          props.errorCallback(true);
          throw new Error('Failed to search for flights');
        }
        return response.json();
      })
      .then(data => {
        props.loadingCallback(false);
        props.errorCallback(false);
        props.resultCallback(true)
        sendDataToParent(data)
        console.log('Search results:', data);
      })
      .catch(error => {
        console.error('Error searching for flights:', error);
      });
  };

  const getDayName = (dateString) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const selectedDate = new Date(dateString);
    return days[selectedDate.getDay()];
  };


  return (
    <div>
      <section id="theme_search_form">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="theme_search_form_area">
                <div className="theme_search_form_tabbtn">
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="flights-tab" data-bs-toggle="tab"
                          data-bs-target="#flights" type="button" role="tab" aria-controls="flights"
                          aria-selected="true"><i className="fas fa-plane-departure"></i>Flights</button>
                    </li>
                  </ul>
                </div>
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="flights" role="tabpanel"
                      aria-labelledby="flights-tab">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="flight_categories_search">
                          <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item" role="presentation">
                              <button className="nav-link active" id="oneway-tab" data-bs-toggle="tab"
                                  data-bs-target="#oneway_flight" type="button" role="tab" onClick={()=>setIsReturn(false)}
                                  aria-controls="oneway_flight" aria-selected="true">One
                                  Way</button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button className="nav-link" id="roundtrip-tab" data-bs-toggle="tab"
                                  data-bs-target="#roundtrip" type="button" role="tab"
                                  aria-controls="roundtrip" onClick={()=>setIsReturn(true)}
                                  aria-selected="false">Roundtrip</button>
                            </li>
                            {/* <li className="nav-item" role="presentation">
                              <button className="nav-link" id="multi_city-tab" data-bs-toggle="tab"
                                  data-bs-target="#multi_city" type="button" role="tab"
                                  aria-controls="multi_city" aria-selected="false">Multi
                                  city</button>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="tab-content" id="myTabContent1">
                      <div className="tab-pane fade show active" id="oneway_flight" role="tabpanel"
                          aria-labelledby="oneway-tab">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="oneway_search_form">
                              <form onSubmit={handleSearch}> {/* Use onSubmit instead of onClick for form submission */}
                                <div className="row">
                                  <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                    <div className="flight_Search_boxed">
                                      <p className='d-none d-sm-block'>From</p>
                                      <input
                                        type="text"
                                        placeholder="From"
                                        value={fromValue}
                                        
                                        onChange={(e) => handleInputChange(e, 'from')}
                                        onFocus={() => fromSuggestionHandler()}
                                       
                                        className={fromError ? 'error' : ''} // Apply error class if fromError is true
                                      />
                                      {fromError && <span className="error-message">From field cannot be empty</span>} {/* Display error message if fromError is true */}
                                      {/* {showFromSuggestions && fromSuggestions.length > 0 && (
                                        <ul className="suggestions-list">
                                          {fromSuggestions.map((suggestion, index) => (
                                            <li key={index} onMouseDown={() => selectSuggestion(suggestion, 'from')}>
                                              {suggestion}
                                              </li>
                                          ))}
                                        </ul>
                                      )} */}

{showFromSuggestions && fromSuggestions.length > 0 && (
                <ul className="suggestions-list" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  {fromSuggestions.map((suggestion, index) => (
                    <li key={index} onMouseDown={() => selectSuggestion(suggestion, 'from')}>
                      {suggestion}
                    </li>
                  ))}
                </ul>
              )}
                                      <span>Flying From Airport?</span>
                                      <div className="plan_icon_posation">
                                        <i className="fas fa-plane-departure"></i>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                    <div className="flight_Search_boxed">
                                      <p className='d-none d-sm-block'>To</p>
                                      <input
                                        type="text"
                                        placeholder="To"
                                        value={toValue}
                                        onChange={(e) => handleInputChange(e, 'to')}
                                        onFocus={() => toSuggestionHandler()}
                                        className={toError ? 'error' : ''} // Apply error class if toError is true
                                      />
                                      {toError && <span className="error-message">To field cannot be empty</span>} {/* Display error message if toError is true */}
                                      {showToSuggestions && toSuggestions.length > 0 && (
                <ul className="suggestions-list" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  {toSuggestions.map((suggestion, index) => (
                    <li key={index} onMouseDown={() => selectSuggestion(suggestion, 'to')}>
                      {suggestion}
                    </li>
                  ))}
                </ul>
              )}
                                      <span>Going to </span>
                                      <div className="plan_icon_posation">
                                        <i className="fas fa-plane-arrival"></i>
                                      </div>
                                      {/* <div className="range_plan">
                                        <i className="fas fa-exchange-alt"></i>
                                      </div> */}
                                    </div>
                                  </div>
                                  
                                  <div className="col-lg-2 col-md-6 col-sm-12 col-12">
                                  <div className="form_search_date">
                                      <div className="flight_Search_boxed date_flex_area">
                                        <div className="Journey_date">
                                          <p>Journey date</p>
                                          <input 
     type="date" 
     value={dateValue} 
     onChange={(e) => handleDateSelect(e)} 
      />
      {/* Display the day name when a date is selected */}
      <span  className='d-none d-sm-block'>{returnDate && getDayName(dateValue)}</span>
    </div>

                                        
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 col-md-6 col-sm-12 col-12">
                                    <div className="flight_Search_boxed dropdown_passenger_area">
                                      <p className='d-none d-sm-block'> Passenger, class </p>
                                      <div className="dropdown">
                                        <button className="dropdown-toggle final-count"
                                          data-toggle="dropdown" type="button" id="dropdownMenuButton1"
                                          data-bs-toggle="dropdown" aria-expanded="false">
                                         {`${passenger} Passenger`}
                                        </button>
                                        <div className="dropdown-menu dropdown_passenger_info"
                                          aria-labelledby="dropdownMenuButton1">
                                          <div className="traveller-calulate-persons">
                                            <div className="passengers">
                                              <h6>Passengers</h6>
                                              <div className="passengers-types">
                                                <div className="passengers-type">
                                                  <div className="text">
                                                    <span className="count pcount">{adult}</span>
                                                    <div className="type-label">
                                                      <p>Adult</p>
                                                      <span>15 yrs and Above</span>
                                                    </div>
                                                  </div>
                                                  <div className="button-set">
                                                    <button type="button" className="btn-add" onClick={(e) => handleAdultCount(e,1)}>
                                                      <i className="fas fa-plus"></i>
                                                    </button>
                                                    <button type="button" className="btn-subtract" onClick={(e) => handleAdultCount(e,-1)}>
                                                      <i className="fas fa-minus"></i>
                                                    </button>
                                                  </div>
                                                </div>


                                                <div className="passengers-type">
                                                  <div className="text">
                                                    <span className="count pcount">{youth}</span>
                                                    <div className="type-label">
                                                      <p>Youth</p>
                                                      <span>12-15 yrs</span>
                                                    </div>
                                                  </div>
                                                  <div className="button-set">
                                                    <button type="button" className="btn-add" onClick={(e) => handleYouthCount(e,1)}>
                                                      <i className="fas fa-plus"></i>
                                                    </button>
                                                    <button type="button" className="btn-subtract" onClick={(e) => handleYouthCount(e,-1)}>
                                                      <i className="fas fa-minus"></i>
                                                    </button>
                                                  </div>
                                                </div>


                                                <div className="passengers-type">
                                                  <div className="text">
                                                    <span className="count ccount">{children}</span>
                                                    <div className="type-label">
                                                      <p className="fz14 mb-xs-0">Children</p>
                                                      <span>2 - Less than 12 yrs</span>
                                                    </div>
                                                  </div>
                                                  <div className="button-set">
                                                    <button type="button" className="btn-add-c" onClick={(e) => handlechildrenCount(e,1)}>
                                                      <i className="fas fa-plus"></i>
                                                    </button>
                                                    <button type="button" className="btn-subtract-c"onClick={(e) => handlechildrenCount(e,-1)}>
                                                      <i className="fas fa-minus"></i>
                                                    </button>
                                                  </div>
                                                </div>
                                                <div className="passengers-type">
                                                  <div className="text">
                                                    <span className="count incount">{infant}</span>
                                                    <div className="type-label">
                                                      <p className="fz14 mb-xs-0">Infant</p>
                                                      <span>Less than 2 yrs</span>
                                                    </div>
                                                  </div>
                                                  <div className="button-set">
                                                    <button type="button" className="btn-add-in" onClick={(e) => handleinfantCount(e,1)}>
                                                      <i className="fas fa-plus"></i>
                                                    </button>
                                                    <button type="button" className="btn-subtract-in" onClick={(e) => handleinfantCount(e,-1)} >
                                                      <i className="fas fa-minus"></i>
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="cabin-selection">
                                              <h6>Cabin Class</h6>
                                              <div className="cabin-list">
                                                <button type="button" className="label-select-btn active" onClick={()=>{setCabinClass("Economy")}}>
                                                  <span className="muiButton-label">Economy</span>
                                                </button>
                                                <button type="button" className="label-select-btn " onClick={()=>{setCabinClass("Premium Economy")}}>
                                                  <span className="muiButton-label">Premium Economy</span>
                                                </button>
                                                <button type="button" className="label-select-btn " onClick={()=>{setCabinClass("Business")}}>
                                                  <span className="muiButton-label">Business</span>
                                                </button>
                                                <button type="button" className="label-select-btn" onClick={()=>{setCabinClass("First class")}}>
                                                  <span className="MuiButton-label">First class </span>
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <span>{cabinClass}</span>
                                    </div>
                                  </div>
{/* phone number field */}
                                  <div className="col-lg-2 col-md-6 col-sm-12 col-12 ">
                                    <div className="flight_Search_boxed">
                                      <p className='d-none d-sm-block'>Phone no</p>
                                      <input
                                        required
                                        type="text"
                                        placeholder="+44"
                                        value={phoneNo}
                                        onChange={(e)=>SetPhoneNo(e.target.value)}
                                      />
                                      <span>Phone Number </span>
                                      <div className="plan_icon_posation">
                                        <i className="fas fa-phone"></i>
                                      </div>
                                    </div>
                                  </div>
                                  
                                  <div className="top_form_search_button">
                                    <button className="btn btn_theme btn_md" type="submit"> {/* Use type="submit" for form submission */}
                                      Search
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                 


                          

                          </div>
                        </div>
                      </div>
{/* //second form     */}


                      <div
                        className="tab-pane fade"
                        id="roundtrip"
                        role="tabpanel"
                        aria-labelledby="roundtrip-tab"
                      >
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="oneway_search_form">
                            <form >
    <div className="row ">
      <div className="col-lg-3 custom-col-3  col-md-6 col-sm-12 col-12">
      <div className="flight_Search_boxed">
                                      <p className='d-none d-sm-block'>From</p>
                                      <input
                                        type="text"
                                        placeholder="From"
                                        value={fromValue}
                                        onChange={(e) => handleInputChange(e, 'from')}
                                        onFocus={() => fromSuggestionHandler()}
                                        className={fromError ? 'error' : ''} // Apply error class if fromError is true
                                      />
                                      {fromError && <span className="error-message">From field cannot be empty</span>} {/* Display error message if fromError is true */}
                                      {/* {showFromSuggestions && fromSuggestions.length > 0 && (
                                        <ul className="suggestions-list">
                                          {fromSuggestions.map((suggestion, index) => (
                                            <li key={index} onMouseDown={() => selectSuggestion(suggestion, 'from')}>
                                              {suggestion}
                                              </li>
                                          ))}
                                        </ul>
                                      )} */}
                                      {showFromSuggestions && fromSuggestions.length > 0 && (
                <ul className="suggestions-list" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  {fromSuggestions.map((suggestion, index) => (
                    <li key={index} onMouseDown={() => selectSuggestion(suggestion, 'from')}>
                      {suggestion}
                    </li>
                  ))}
                </ul>
              )}
                                      <span>Flying From Airport?</span>
                                      <div className="plan_icon_posation">
                                        <i className="fas fa-plane-departure"></i>
                                      </div>
                                    </div>
      </div>
      <div className="col-lg-3 custom-col-3  col-md-6 col-sm-12 col-12">
      <div className="flight_Search_boxed">
                                      <p className='d-none d-sm-block'>To</p>
                                      <input
                                        type="text"
                                        placeholder="To"
                                        value={toValue}
                                        onChange={(e) => handleInputChange(e, 'to')}
                                        onFocus={() => toSuggestionHandler()}
                                        className={toError ? 'error' : ''} // Apply error class if toError is true
                                      />
                                      {toError && <span className="error-message">To field cannot be empty</span>} {/* Display error message if toError is true */}
                                      {/* {showToSuggestions && toSuggestions.length > 0 && (
                                        <ul className="suggestions-list">
                                          {toSuggestions.map((suggestion, index) => (
                                            <li key={index} onMouseDown={() => selectSuggestion(suggestion, 'to')}>
                                              {suggestion}
                                            </li>
                                          ))}
                                        </ul>
                                      )} */}
                                      {showToSuggestions && toSuggestions.length > 0 && (
                <ul className="suggestions-list" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  {toSuggestions.map((suggestion, index) => (
                    <li key={index} onMouseDown={() => selectSuggestion(suggestion, 'to')}>
                      {suggestion}
                    </li>
                  ))}
                </ul>
              )}
                                      <span>Going to</span>
                                      <div className="plan_icon_posation">
                                        <i className="fas fa-plane-arrival"></i>
                                      </div>
                                      {/* <div className="range_plan">
                                        <i className="fas fa-exchange-alt"></i>
                                      </div> */}
                                    </div>
      </div>
      <div className="col-lg-3 custom-col-3 col-md-6 col-sm-12 col-12">
        <div className="form_search_date">
          <div className="flight_Search_boxed date_flex_area">
          <div className="Journey_date">
        <p>Journey date</p>
        <input 
          type="date" 
          value={dateValue} 
          onChange={(e) => handleDateSelect(e)} 
        />
        {/* Display the day name dynamically */}
        {dateValue && <span className='d-none d-sm-block'>{getDayName(dateValue)}</span>}
      </div>
      
      

                                        <div className="Journey_date">
      <p>Return date</p>
      <input 
        type="date" 
        value={returnDate} 
        onChange={(e) => setReturnDate(e.target.value)} 
      />
      {/* Display the day name when a date is selected */}
      <span className='d-none d-sm-block'>{returnDate && getDayName(returnDate)}</span>
    </div>
          </div>
        </div>
      </div>
      <div className="col-lg-2 col-md-6 col-sm-12 col-12">
                                    <div className="flight_Search_boxed dropdown_passenger_area">
                                      <p className='d-none d-sm-block'>Passenger, class </p>
                                      <div className="dropdown">
                                        <button className="dropdown-toggle final-count"
                                          data-toggle="dropdown" type="button" id="dropdownMenuButton1"
                                          data-bs-toggle="dropdown" aria-expanded="false">
                                         {`${passenger} Passenger`}
                                        </button>
                                        <div className="dropdown-menu dropdown_passenger_info"
                                          aria-labelledby="dropdownMenuButton1">
                                          <div className="traveller-calulate-persons">
                                            <div className="passengers">
                                              <h6>Passengers</h6>
                                              <div className="passengers-types">
                                                <div className="passengers-type">
                                                  <div className="text">
                                                    <span className="count pcount">{adult}</span>
                                                    <div className="type-label">
                                                      <p>Adult</p>
                                                      <span>15 and Above yrs</span>
                                                    </div>
                                                  </div>
                                                  <div className="button-set">
                                                    <button type="button" className="btn-add" onClick={(e) => handleAdultCount(e,1)}>
                                                      <i className="fas fa-plus"></i>
                                                    </button>
                                                    <button type="button" className="btn-subtract" onClick={(e) => handleAdultCount(e,-1)}>
                                                      <i className="fas fa-minus"></i>
                                                    </button>
                                                  </div>
                                                </div>

                                                <div className="passengers-type">
                                                  <div className="text">
                                                    <span className="count pcount">{youth}</span>
                                                    <div className="type-label">
                                                      <p>Youth</p>
                                                      <span>12-15 yrs</span>
                                                    </div>
                                                  </div>
                                                  <div className="button-set">
                                                    <button type="button" className="btn-add" onClick={(e) => handleYouthCount(e,1)}>
                                                      <i className="fas fa-plus"></i>
                                                    </button>
                                                    <button type="button" className="btn-subtract" onClick={(e) => handleYouthCount(e,-1)}>
                                                      <i className="fas fa-minus"></i>
                                                    </button>
                                                  </div>
                                                </div>


                                                <div className="passengers-type">
                                                  <div className="text">
                                                    <span className="count ccount">{children}</span>
                                                    <div className="type-label">
                                                      <p className="fz14 mb-xs-0">Children</p>
                                                      <span>2 - Less than 12 yrs</span>
                                                    </div>
                                                  </div>
                                                  <div className="button-set">
                                                    <button type="button" className="btn-add-c" onClick={(e) => handlechildrenCount(e,1)}>
                                                      <i className="fas fa-plus"></i>
                                                    </button>
                                                    <button type="button" className="btn-subtract-c"onClick={(e) => handlechildrenCount(e,-1)}>
                                                      <i className="fas fa-minus"></i>
                                                    </button>
                                                  </div>
                                                </div>
                                                <div className="passengers-type">
                                                  <div className="text">
                                                    <span className="count incount">{infant}</span>
                                                    <div className="type-label">
                                                      <p className="fz14 mb-xs-0">Infant</p>
                                                      <span>Less than 2 yrs</span>
                                                    </div>
                                                  </div>
                                                  <div className="button-set">
                                                    <button type="button" className="btn-add-in" onClick={(e) => handleinfantCount(e,1)}>
                                                      <i className="fas fa-plus"></i>
                                                    </button>
                                                    <button type="button" className="btn-subtract-in" onClick={(e) => handleinfantCount(e,-1)} >
                                                      <i className="fas fa-minus"></i>
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="cabin-selection">
                                              <h6>Cabin class</h6>
                                              <div className="cabin-list">
                                                <button type="button" className="label-select-btn active" onClick={()=>{setCabinClass("Economy")}}>
                                                  <span className="muiButton-label">Economy</span>
                                                </button>
                                                <button type="button" className="label-select-btn "  onClick={()=>{setCabinClass("Business")}}>
                                                  <span className="muiButton-label">Business</span>
                                                </button>
                                                <button type="button" className="label-select-btn" onClick={()=>{setCabinClass("First class")}}>
                                                  <span className="MuiButton-label">First class </span>
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <span>{cabinClass}</span>
                                    </div>
                                  </div>
{/* phone number field */}

                                  <div className="col-lg-2 col-md-6 col-sm-12 col-12 " >
                                    <div className="flight_Search_boxed">
                                      <p className='d-none d-sm-block'>Phone no</p>
                                      <input
                                        required
                                        type="text"
                                        placeholder="+44"
                                        value={phoneNo}
                                        onChange={(e)=>SetPhoneNo(e.target.value)}
                                      />
                                      <span>Phone Number </span>
                                      <div className="plan_icon_posation">
                                        <i className="fas fa-phone"></i>
                                      </div>
                                    </div>
                                  </div>
    </div>
    <div className="top_form_search_button">
      <button className="btn btn_theme btn_md" onClick={(e)=>handleRoundTripSearch(e)}>
        Search
      </button>
    </div>
  </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

       {/* Modal Component */}
       <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>One of our agent will contact you Soon.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>

    
  );
};

export default Form;
