import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const FlightList = () => {
    const [flights, setFlights] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
            navigate('/login');  // Redirect to login if no access token
        } else {
            fetchFlights();
        }
    }, [navigate]);

    const fetchFlights = async () => {
        try {
            const response = await axios.get('https://flightsway-service-ydsxc44xva-uc.a.run.app/api/flight-search-user/', {
                headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
            });
            setFlights(response.data);
        } catch (error) {
            console.error('Error fetching flights:', error);
            // Optionally handle expired/invalid token by redirecting to login
            if (error.response && error.response.status === 401) {
                navigate('/login');
            }
        }
    };

    const deleteFlight = async (id) => {
        try {
            await axios.delete(`https://flightsway-service-ydsxc44xva-uc.a.run.app/api/flight-search-user/delete/${id}/`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
            });
            fetchFlights();  // Refresh the list after deletion
        } catch (error) {
            console.error('Error deleting flight:', error);
        }
    };

    return (
        <>
        <div className="container mt-5">
            <h2 className="mb-3 text-center">Flight Search List</h2>
            <table className="table table-striped">
                <thead className="thead-dark">
                    <tr>
                        <th>ID</th>
                        <th>Origin</th>
                        <th>Destination</th>
                        <th>From Date</th>
                        <th>To Date</th>
                        <th>Phone Number</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {flights.map(flight => (
                        <tr key={flight.id}>
                            <td>{flight.id}</td>
                            <td>{flight.origin}</td>
                            <td>{flight.destination}</td>
                            <td>{flight.from_date}</td>
                            <td>{flight.to_date}</td>
                            <td>{flight.phone_number}</td>
                            <td>
                                <button className="btn btn-danger mr-2" onClick={() => deleteFlight(flight.id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

        </>
    );
};

export default FlightList;