import React from 'react';
import { useState } from 'react' 
import { useLocation } from 'react-router-dom';
import Footer from './Footer';
import image from './logo2.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import "./ResultPageHeader.css"

const ResultsPage = () => {
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

  // Function to toggle mobile menu visibility
  const toggleMobileMenu = () => {
    setIsMobileMenuVisible(!isMobileMenuVisible);
  };
    const location = useLocation();
    const { iframeurl } = location.state || {}; // Access the state passed via navigate

    return (
      <>
      <header className="custom_header_arae" >
{/* <!-- Top Bar --> */}
<div className="topbar-area">
    <div className="container">
        <div className="row align-items-center ">
            <div className="col-lg-6 col-md-6">
                <ul className="topbar-list">
                    <li>
                        <a href="https://www.facebook.com/FlightsWayltd" target='blank'><i className="fab fa-facebook"></i></a>
                        <a href="https://wa.me/message/https://wa.me/+447762497412?text=Hello%20Flightways%20Team%2C%20I%20need%20help%20booking%20a%20flightVJDZMED22Z4SJ1"><i className="fab fa-whatsapp-square"></i></a>
                        <a href="https://www.instagram.com/flightswayuk/" target='blank'><i className="fab fa-instagram"></i></a>
                       
                    </li>
                    <li><a href="#!"><span>info@flightsway.co.uk</span></a></li>
                </ul>
            </div>
            <div className="col-lg-6 col-md-6">

            </div>
        </div>
    </div>
</div>
      
        {/* Mobile View Container */}
        
        <div className="mobile-header">
          <div className="logo-mobile">
            <a href="/">
              <img src={image} alt="Logo" />
            </a>
          </div>
          <div className="mobile-phone-number">
            <a href="tel:02032871342">
            <FontAwesomeIcon icon={faPhone} />
                <span className='ps-1 fw-bold'>020 3287 1342 </span>
                
                </a>
          </div>
          <button className="hamburger-menu" onClick={toggleMobileMenu}>
            ☰ {/* Hamburger Icon */}
          </button>
        </div>
        


        {/* Mobile Navigation Menu */}
        {isMobileMenuVisible && (
          <div className="mobile-nav">
            <ul>
              <li><a href="#">Flights</a></li>
              <li><a href="#">Trending</a></li>
              <li><a href="/contact">Contact</a></li>
              <li><a href="/privacy">Privacy Policy</a></li>
              <li><a href="/terms">Terms Of Use</a></li>
            </ul>
          </div>
        )}



{/* <!-- Navbar Bar --> */}
<div className="navbar-area">
    <div className="main-responsive-nav">
        <div className="container">
            <div className="main-responsive-menu">
                <div className="logo">
                    <a href="/">
                        <img src="assets/img/logo.png" alt="FlightWay"/>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div className="main-navbar">
        <div className="container">
            <nav className="navbar navbar-expand-md navbar-light">
                <a className="navbar-brand" href="/">
                    <img src="assets/img/logo.png" alt="logo"/>
                </a>
                <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a href="#" className="nav-link active">
                                Flights
                                <i className="fas fa-angle-down"></i>
                            </a>
                            <ul className="dropdown-menu">
                                <li className="nav-item">
                                    <a href="/" className="nav-link active">Cheap Flights</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/" className="nav-link">Business Class Flights</a>
                                </li>
                                 <li className="nav-item">
                                    <a href="/" className="nav-link">Flights to Ghana</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/" className="nav-link">Flights to Kenya</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/" className="nav-link">Flights to Nigeria</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/" className="nav-link">Flights to South Afica</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/" className="nav-link">Flights to Zimbabwe</a>
                                </li>
                                
                            </ul>
                        </li>

                        <li className="nav-item">
                            <a href="#" className="nav-link">
                                Holidays
                            </a>
                        </li>

                        <li className="nav-item">
                            <a href="#" className="nav-link">
                                Trending
                            </a>
                        </li>

                        <li className="nav-item">
                            <a href="/contact" className="nav-link">
                                contact
                            </a>
                        </li>

                        <li className="nav-item">
                            <a href="#" className="nav-link">
                                Fly Now Pay Later
                            </a>
                        </li>

                        <li className="nav-item">
                            <a href="#" className="nav-link">
                                Blog
                            </a>
                        </li>
                    </ul>
                    <div className="others-options d-flex align-items-center">
                        <div className="option-item">
                            <a href="https://wa.me/+447762497412?text=Hello%20Flightways%20Team%2C%20I%20need%20help%20booking%20a%20flight" target="_blank" className="btn btn_navber">Contact Us </a>                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
    <div className="others-option-for-responsive">
        <div className="container">
            <div className="dot-menu">
                <div className="inner">
                    <div className="circle circle-one"></div>
                    <div className="circle circle-two"></div>
                    <div className="circle circle-three"></div>
                </div>
            </div>
            <div className="container">
                <div className="option-inner">
                    <div className="others-options d-flex align-items-center">
                        <div className="option-item">
                            <a href="#" className="search-box"><i className="fas fa-search"></i></a>
                        </div>
                        <div className="option-item">
                            <a href="contact.html" className="btn  btn_navber">Get free quote</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</header>
 
        <section >
        {iframeurl ? (
                <iframe src={iframeurl} title="Search Results" frameborder="0"></iframe>
            ) : (
                <p className='text-center'>No results to display.</p>
            )}
    </section>


        <Footer/>

        <div id="mobile-sticky-footer" className='container-fluid d-lg-none'>
        <div className="row">
            <div className="col-12 p-0 sticky-nav-index" >
                <div className='mobile-sticky-nav text-center'>
                    <div className="row backk" >
                        <div className="col">
                            <a className='p-2 text-white' target='blank' href="https://api.whatsapp.com/send?phone=447762497412&text=Hi%20Flights%20Way%20UK,%20I%27m%20interested%20in%20flights%20deal%20for%20">
                                <i className='fab fa-whatsapp fa-2x'></i>
                                <span>Whatsapp</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
      </>

    );
};

export default ResultsPage;
