import React from 'react'
import Header from './Header'
import Footer from './Footer'

const TermsOfUse = () => {
  return (
    <>
    <Header/>
      {/* <!-- Common Banner Area --> */}
    <section id="common_banner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="common_bannner_text">
                        <h2>Terms Of Service</h2>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li><span><i class="fas fa-circle"></i></span> Terms Of Service</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* <!-- Trems_service Areas --> */}
    <section id="tour_details_main" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="terms_service_content">
                        <div class="terms_item">
                            <h4>Terms and Conditions</h4>
                            <p>
                            All bookings made with Flights Way Ltd are subject to the following terms and conditions. Please read these carefully, as they outline the rights and obligations of our organization. In these booking conditions, ‘we’ and ‘us’ refer to Flights Way Ltd, a registered company under Companies House UK with registration number 13581977. ‘You’ or ‘your’ includes the individual named on the booking and all individuals on whose behalf the booking is made, transferred, or added. If any of the booking individuals disagree with any part of these terms and conditions, they should refrain from engaging in any dealings with our company
                            </p>
                            
                        </div>

                        <div class="terms_item">
                            <h4>Online Bookings</h4>
                            <p>
                            When booking online, you must provide us with all the necessary information and ensure that the debit or credit card used is your own (or that you have the authority to use someone else’s debit or credit card). It is your responsibility to have sufficient funds to cover the cost of the travel arrangements you book with us. Upon confirming your booking, we will send you a confirmation invoice and email. We make no representations or guarantees regarding the availability of any flight or individual components, or that our reservation services are free from viruses or other malicious influences on your property.
                            </p>
                            
                        </div>
                        <div class="terms_item">
                            <h4>Telephone Bookings</h4>
                            <p>
                            For telephone bookings, you are required to provide us with all necessary information and ensure that the debit or credit card used is your own (or that you have the authority to use someone else’s debit or credit card). It is your responsibility to have sufficient funds to cover the cost of the travel arrangements you book with us. Upon confirming your booking, we will send you a confirmation invoice and email. Please carefully review all details upon receipt, and notify us immediately if you identify any discrepancies, as changes may not be possible at a later stage.
                            </p>
                            
                        </div>
                        <div class="terms_item">
                            <h4>Data Security and Privacy</h4>
                            <p>
                            We process your reservation and ensure that your travel arrangements are efficient and tailored to your needs based on the information you provide, including:

                            <li>Name, address, contact numbers (cell phone, landline), and email address.</li>
                            <li>Details of your visits to our website, including location data, traffic data, weblogs, and other communication information.</li>
                            <li>Information provided when filling out forms on our website for information or service acquisition.</li>
                            <li>Any other data provided during communication for various purposes.</li>

                            Some of the data we collect may be considered “sensitive personal data” under the Data Protection Act 1998. We collect this data only to serve your needs or act in your interest, and we request your consent when necessary.
                            </p>
                        </div>

                        <div class="terms_item">
                            <h4>Visa and Passport Requirements</h4>
                            <p>
                            It is your responsibility to verify the validity of your travel documents, including visas and passports, to ensure they are in order.
                            </p>
                        </div>
                        <div class="terms_item">
                            <h4>Visas and Passports</h4>
                            <p>
                            You must check with the respective consulates or embassies for visa and passport requirements. We are not liable if you are denied boarding or entry to a country due to your own failure to meet passport or visa requirements or the unavailability of these documents. Your passport should be valid for at least 6 months beyond your return date, and your health and visa documents should be up-to-date for all the countries you plan to visit, including transit locations. This includes all airports where your plane stops, whether you leave the airport or not. If your visa is expiring in the next year, consult the embassy of the country you plan to visit. If you require further assistance, contact the passport office or visit the Foreign and Commonwealth Office website at www.fco.gov.uk.
                            </p>
                        </div>
                        <div class="terms_item">
                            <h4>Conduct</h4>
                            <p>
                            You are responsible for your behavior and that of your party. Your conduct must not be offensive, pose a threat to others, or damage others’ property. If damage occurs, compensation must be made directly to the owner, administrator, or other provider. We expect all customers to show consideration for other travelers. If, in our reasonable opinion or the opinion of any other authority, your conduct is causing or likely to cause discomfort, harm, or a threat to any third party or property, we reserve the right to terminate your travel arrangements without notice. In such a situation, your entire reservation with us, including return travel arrangements, will be terminated immediately, and we will not be liable for any fares, expenses, refunds, or compensation.
                            </p>
                        </div>

                        <div class="terms_item">
                            <h4>Compliance with Laws</h4>
                            <p>
                            You must also comply with the laws, regulations, and drug laws of the countries you visit. We and our authorities reserve the right to cancel your reservations at any time if, in our reasonable opinion, you are found to be acting in a manner that is socially unacceptable or engaging in illegal activities, without any liability on our part for any cost, refund, or legal action.
                            </p>
                                                        

                        </div>



                        <div class="terms_item">
                            <h4>Insurance</h4>
                            <p>
                            You have the option to purchase travel insurance through us or obtain it independently. Your insurance coverage should include cancellation charges, sudden trip interruptions, repatriation, medical expenses (including air ambulance), personal accidents, and loss or damage to personal belongings.
                            </p>
                                                        

                        </div>

                        <div class="terms_item">
                            <h4>Flights and Travel Documents</h4>
                            <p>
                            Please note that a flight referred to as “direct” on your air ticket may not actually be direct. The arrival and departure times on your plane tickets are approximate and set by the airline. These times are subject to change due to air traffic control regulations, weather conditions, maintenance, or operational requirements. We cannot make special arrangements for you if you are delayed; your eligibility for rebooking is at the sole discretion of the airline.
                            </p>

                            <p>Regarding in-flight matters, a child must be under 2 years old on the day of the return flight to be eligible for a fare of 10% of IATA’s published cost.</p>

                            <p>Please be aware that if a portion of your flight itinerary is not used, any subsequent segments may be canceled without prior notice. In such a case, we cannot accept liability for any expenses incurred.</p>

                            <p>
                            In compliance with EU regulations, we are required to inform you of the existence of the “Community list,” which includes details of air carriers that are subject to a ban on operating within the EU. You can find this list at http://ec.europa.eu/transport/air-ban. We reserve the right to change the airline if the airline is in violation of EU regulations. Any changes to the airline that affect your booking will be communicated to you as soon as possible, either at the boarding gate or check-in.
                            </p>
                                                        

                        </div>


                        <div class="terms_item">
                            <h4>Confirmation of All Flights</h4>
                            <p>
                            Contact us or the airline’s office at least 72 hours before your scheduled departure time to confirm that no changes have occurred, both for outbound and return flights. We will not be responsible for any additional costs resulting from your failure to confirm your flights. Confirming your flight at least 72 hours in advance is the minimum requirement.
                            </p>
                                                        

                        </div>



                        <div class="terms_item">
                            <h4>Reservations and Accommodations</h4>
                            <p>
                            The hotel and accommodation ratings on our website are based on multiple sources and are meant to provide a general guideline. Please note that these ratings are not official, and the standards of facilities can vary from one country to another and even within the same country. For instance, a 3-star hotel in one location may not offer the same level of comfort as a 3-star hotel elsewhere. We do not provide any exclusive use of accommodations listed on our site. These accommodations are booked by travelers from various countries and cultures and of different age groups.
                            </p>
                                                        

                        </div>



                        <div class="terms_item">
                            <h4>Check-In and Check-Out Times</h4>
                            <p>
                            Generally, guests can check in and check out according to local time, which may be 3 pm for check-out and 11 am for check-in or based on local timing. For specific times, please contact our customer service at Flights Way Ltd.
                            </p>
                                                        

                        </div>


                        <div class="terms_item">
                            <h4>Images</h4>
                            <p>
                            We do our best to display images on the Flights Way Ltd website accurately, but we do not guarantee that they accurately represent the actual property. The images are provided for a general overview of the hotel or accommodation.
                            </p>
                                                        

                        </div>

                        <div class="terms_item">
                            <h4>Facilities, Amenities, and Descriptions</h4>
                            <p>
                            We strive to ensure that the information regarding facilities, amenities, and descriptions is accurate and up-to-date, but this data is obtained from other travel providers and may not always be current. The information serves as a general description of the property.
                            </p>
                                                        

                        </div>


                        <div class="terms_item">
                            <h4>Additional Beds or Cots</h4>
                            <p>
                            Additional charges may apply for extra cots or beds. Please contact our customer services at Flights Way Ltd for more information. Unless explicitly stated, breakfast is not included in the services. Some hotels may charge additional local taxes.
                            </p>
                                                        

                        </div>


                        <div class="terms_item">
                            <h4>Refund of Airline Tickets</h4>
                            <p>
                            Refunded air tickets are subject to administration charges of £45 per ticket. Economy class tickets are non-refundable according to airline policies. Regardless of the number of tickets you return, you are responsible for paying the cancellation fee per ticket as required by the airline and in accordance with their terms and conditions. There is no automatic right to a full refund of the ticket. If you request a refund, we will arrange for it with the relevant airline according to their terms and conditions. Refunds will not be issued until the relevant airline or consolidator has paid us the refund amount. Air tickets are typically refunded within 6 to 7 weeks from the date of ticket submission to the airline.
                            </p>
                                                        

                        </div>


                        <div class="terms_item">
                            <h4>Flight Changes</h4>
                            <p>
                            In the event of flight cancellation, your rights and compensation are subject to the airline’s carriage conditions. If there is a change in the schedule of your flight before we receive full payment for your booking or before the ticket (either outbound or return) is issued, we will do our best to inform you on behalf of the airline. Passengers have the option to change their travel date in case of a schedule change or flight cancellation by up to 3 days, depending on flight availability. Please note that changing outside of this 3-day window will result in an administration fee of £45, plus any fare difference if applicable.
                            </p>

                            <p>
                            Flights Way Ltd provides general information on its website for guidance purposes only. We reserve the right to alter any part or feature of the website, its contents, or its resources at any time, including dealers, features, databases, information, or content. You are responsible for confirming travel providers, destinations, and embassy or tourism office details to ensure the information is up-to-date. Specifically regarding passports, visas, and travel requirements, Flights Way Ltd does not guarantee that the information is always current, and it is your responsibility to understand and meet the requirements for visas, passports, and vaccinations. Our website uses cookies to track browsing preferences. If you allow cookies in your browser, personal information may be stored with us and may be used by third parties.
                            </p>

                            <p>
                            Flights Way Ltd does not guarantee the accuracy or availability of the information on the website, including descriptions, dates, and prices. While we make every effort to correct errors or add missing information as soon as we are notified, we cannot be held liable for any inaccuracies. For example, most of the hotels and accommodations described on our site are added by travel providers or the specific hotels. You use any information on this website at your own risk, and we are not liable for it. It is your responsibility to ensure that any product, information, or service acquired from this website meets your needs.
                            </p>
                                                        

                        </div>

                        

                    </div>
                </div>
            </div>
        </div>
    </section>


    {/* <!-- Cta Area --> */}
    <section id="cta_area">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="cta_left">
                        <div className="cta_icon">
                            <img src="assets/img/common/email.png" alt="icon"/>
                        </div>
                        <div className="cta_content">
                            <h4>Get the latest news and offers</h4>
                            <h2>Subscribe to our newsletter</h2>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="cat_form">
                        <form id="cta_form_wrappper">
                            <div className="input-group"><input type="text" class="form-control"
                                    placeholder="Enter your mail address"/><button class="btn btn_theme btn_md"
                                    type="button">Subscribe</button></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <Footer/>
    <div id="mobile-sticky-footer" className='container-fluid d-lg-none'>
        <div className="row">
            <div className="col-12 p-0">
                <div className='mobile-sticky-nav text-center'>
                    <div className="row backk" >
                        <div className="col">
                            <a className='p-2 text-white' target='blank' href="https://api.whatsapp.com/send?phone=447762497412&text=Hi%20Flights%20Way%20UK,%20I%27m%20interested%20in%20flights%20deal%20for%20">
                                <i className='fab fa-whatsapp fa-2x'></i>
                                <span>Whatsapp</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    </>
  )
}

export default TermsOfUse