import React, { useState } from 'react';
import './ContactUS.css'
import Header from './Header';
import Footer from './Footer';

const base_url = "https://flightsway-service-ydsxc44xva-uc.a.run.app"

const ContactUs = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    mobile_phone: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
  
    try {
      const response = await fetch(`${base_url}/api/inquiries/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      
      // Check if the response is successful (status code 2xx)
      if (response.ok) {
        alert('Message sent successfully');
        // Reset form or handle success
      } else {
        // Handle server error
        const data = await response.json();
        throw new Error(data.message || 'Something went wrong');
      }
    } catch (error) {
      // Handle network error or server error
      alert(error.message || 'Failed to send message');
    }
  };

  return (
    <>
      <Header />
      {/* <!-- Common Banner Area --> */}
      <section id="common_banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="common_bannner_text">
                <h2>Contact</h2>
                <ul>
                  <li><a href="/">Home</a></li>
                  <li><span><i className="fas fa-circle"></i></span>Contact</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

  

      {/* <!-- Contact Area --> */}
      <section id="contact_main_area" className="section_custom_padding">
        <div className="container">
          <div className="contact_main_form_area">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="section_heading_center">
                  <h2>Leave us a message</h2>
                </div>
                <div className="contact_form">
                  <form onSubmit={handleSubmit} id="contact_form_content">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input type="text" className="form-control bg_input" placeholder="First name*" name="first_name" value={formData.first_name} onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input type="text" className="form-control bg_input" placeholder="Last name*" name="last_name" value={formData.last_name} onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input type="email" className="form-control bg_input" placeholder="Email address (Optional)" name="email" value={formData.email} onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input type="text" className="form-control bg_input" placeholder="Mobile number*" name="mobile_phone" value={formData.mobile_phone} onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <textarea className="form-control bg_input" rows="5" placeholder="Message" name="message" value={formData.message} onChange={handleChange}></textarea>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <button type="submit" className="btn btn_theme btn_md">Send message</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container pt-md-4 pb-5 pt-lg-5 px-3 px-md-4 px-lg-5 p-sm-5">
      
      <div className="row custom-bg">
        <div className="col-md-6 location-flex">
          <div className="location-details">
            <h2 className="add-head">Flights Way – Corporate Office</h2>
            {/* <p>Islamabad, Pakistan ( Corporate Head Office) </p> */}
            <p>Lytchett house, 13 Freeland Pk, Wareham Rd, Lytchett Matravers, Poole BH16 6FA, United Kingdom</p>
            {/* <p>United Kingdom.</p> */}
            <p>For any assistance please contact us 24/7 Customer Support Service </p>
            <p className="phone-no-con"> UAN Number <a href="tel:+442032871342" className='aclr'> +44 20 3287 1342</a></p>
            {/* <p className="phone-no-con"> WhatsApp us at <a href="https://wa.me/923041110636" target='blank' className='aclr'> +92 304 111 0636</a></p> */}
          </div>
        </div>
        
        <div className="col-md-6">
          <div className="mapouter">
            <div className="gmap_canvas">
            <iframe
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12345.1234567890!2d-2.0748377!3d50.7586424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4873af9e3a7ee755%3A0x334a358744a3ebf3!2sFlights+Way+Ltd!5e0!3m2!1sen!2s!]"
          width="100%"
          height="200"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div> 

      <Footer />

      <div id="mobile-sticky-footer" className='container-fluid d-lg-none'>
        <div className="row">
            <div className="col-12 p-0 sticky-nav-index" >
                <div className='mobile-sticky-nav text-center'>
                    <div className="row backk" >
                        <div className="col">
                            <a className='p-2 text-white' target='blank' href="https://api.whatsapp.com/send?phone=447762497412&text=Hi%20Flights%20Way%20UK,%20I%27m%20interested%20in%20flights%20deal%20for%20">
                                <i className='fab fa-whatsapp fa-2x'></i>
                                <span>Whatsapp</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    </>
  );
}

export default ContactUs;
