import React from 'react'
import Header from './Header'
import Footer from './Footer'

const PrivacyPolicy = () => {
    
  return (
    <>
    <Header/>

    

    <section id="common_banner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="common_bannner_text">
                        <h2>Privacy Policy</h2>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li><span><i class="fas fa-circle"></i></span> Privacy Policy</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>


    {/* <!-- Room Details Areas --> */}
    <section id="tour_details_main" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="terms_service_content">
                        <div class="terms_item">
                            <h4>What data do we collect?</h4>

                            <p>Flights Way Ltd collects the following data:</p>
                            <p>
                            <ol>
                                <li>Personal identification information (name, email address, phone number, etc.)</li>
                                <li>Date of birth, passport information, visa information, gender, and membership card details of third-party travel suppliers, including airlines and hotels.</li>
                            </ol>
                            
                            </p>
                            <h4>How do we collect your data?</h4>
                            <p>
                            You directly provide Flights Way Ltd with most of the data we collect. We collect data and process data when you:
                           
                                <li>Register online or place an order for any of our products or services.</li>
                                <li>Voluntarily complete a customer survey or provide feedback on any of our message boards or via email.</li>
                                <li>Use or view our website via your browser’s cookies.</li>
                                <li>Make a travel-related enquiry over the phone or online.</li>
                            
                            </p>
                           
                            
                        </div>
                        <div class="terms_item">
                            <h4>How will we use your data?</h4>
                            <p>Flights Way Ltd collects your data so that we can:</p>
                            <p>
                                
                                    <li>Process your order and manage your account.</li>
                                    <li>Email you with special offers on other products and services we think you might like.</li>
                                    <li>Inform you of any disruption to your travel arrangements.</li>
                                
                            </p>

                            <p>
                            Flights Way Ltd does not share any data with any partner companies.
                            </p>
                            <p>When Flights Way Ltd processes your order, we may send your data to and also use the resulting information from credit reference agencies to prevent fraudulent purchases.</p>
                            
                        </div>


                        <div class="terms_item">
                            <h4>Marketing</h4>
                            <p>Flights Way Ltd would like to send you information about products and services of ours that we think you might like.</p>
                            <p>If you have agreed to receive marketing, you may always opt-out at a later date.</p>
                            <p>You have the right at any time to stop Flights Way Ltd from contacting you for marketing purposes.</p>
                        </div>



                        <div class="terms_item">
                            <h4>What are your data protection rights?</h4>
                            <p>
                            Flights Way Ltd would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
                            </p>
                            <p>
                                    <li>The right to rectification: You have the right to request that Flights Way Ltd correct any information you believe is inaccurate. You also have the right to request Flights Way Ltd to complete information you believe is incomplete.</li>
                                    <li>The right to erasure: You have the right to request that Flights Way Ltd erase your personal data, under certain conditions.</li>
                                    <li>The right to restrict processing: You have the right to request that Flights Way Ltd restrict the processing of your personal data, under certain conditions.</li>
                            </p>
                            <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email: info@flightsway.co.uk</p>
                        </div>


                        <div class="terms_item">
                            <h4>What are cookies?</h4>
                            <p>
                            Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our websites, we may collect information from you automatically through cookies or similar technology.
                            </p>
                            <p>
                            For further information, visit allaboutcookies.org
                            </p>
                        </div>

                        <div class="terms_item">
                            <h4>How do we use cookies?</h4>
                            <p>
                            Flights Way Ltd uses cookies in a range of ways to improve your experience on our website, including:
                            </p>
                            <p>
                               <li>Keeping you signed in.</li>
                               <li>Understanding how you use our website.</li>
                            </p>
                        </div>


                        <div class="terms_item">
                            <h4>What types of cookies do we use?</h4>
                            <p>
                            There are a number of different types of cookies; however, our website uses:
                            </p>
                            <p>
                                <li>Functionality: Flights Way Ltd uses these cookies so that we recognize you on our website and remember your previously selected preferences. These could include what language you prefer and the location you are in. A mix of first-party and third-party cookies is used.</li>
                                <li>Advertising: Flights Way Ltd uses these cookies to collect information about your visit to our website, the content you viewed, the links you followed, and information about your browser, device, and your IP address. Flights Way Ltd sometimes shares some limited aspects of this data with third parties for advertising purposes. We may also share online data collected through cookies with our advertising partners. This means that when you visit another website, you may be shown advertising based on your browsing patterns on our website.</li>
                            </p>
                        </div>



                        <div class="terms_item">
                            <h4>How to manage cookies</h4>
                            <p>
                            You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.
                            </p>
                        </div>


                        <div class="terms_item">
                            <h4>Privacy policies of other websites</h4>
                            <p>
                            The Flights Way Ltd website contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.
                            </p>
                        </div>


                        <div class="terms_item">
                            <h4>How to contact us</h4>
                            <p>
                            If you have any questions about Flights Way Ltd’s privacy policy or the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us via call or email.
                            </p>
                        </div>

                        <div class="terms_item">
                            <h4>How to contact the appropriate authority</h4>
                            <p>
                            Should you wish to report a complaint or if you feel that Flights Way Ltd has not addressed your concern in a satisfactory manner, you may contact the Information Commissioner’s Office at https://ico.org.uk/global/privacy-notice/make-a-complaint/
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> 


    {/* <!-- Cta Area --> */}
    <section id="cta_area">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="cta_left">
                        <div className="cta_icon">
                            <img src="assets/img/common/email.png" alt="icon"/>
                        </div>
                        <div className="cta_content">
                            <h4>Get the latest news and offers</h4>
                            <h2>Subscribe to our newsletter</h2>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="cat_form">
                        <form id="cta_form_wrappper">
                            <div className="input-group"><input type="text" class="form-control"
                                    placeholder="Enter your mail address"/><button class="btn btn_theme btn_md"
                                    type="button">Subscribe</button></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <Footer/>
    <div id="mobile-sticky-footer" className='container-fluid d-lg-none'>
        <div className="row">
            <div className="col-12 p-0">
                <div className='mobile-sticky-nav text-center'>
                    <div className="row backk" >
                        <div className="col">
                            <a className='p-2 text-white' target='blank' href="https://api.whatsapp.com/send?phone=447762497412&text=Hi%20Flights%20Way%20UK,%20I%27m%20interested%20in%20flights%20deal%20for%20">
                                <i className='fab fa-whatsapp fa-2x'></i>
                                <span>Whatsapp</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    </>
  )
}

export default PrivacyPolicy